<template>
    <div class="ml-10 mr-10">
        <TitleBar>
            {{ $t("menu.credentials") }}
            <v-btn
                class="mx-2"
                color="secondary"
                fab
                small
                to="/credentials/new"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
            </v-btn>
        </TitleBar>

        <List :fields="['token', 'permissions', 'description', 'expired_at']" api-key="credentials"
              api-url="/credentials"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Dashboard",
    components: {TitleBar, List},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.credentials"),
                to: "/credentials",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>